module.exports = {
  siteTitle: 'Đừng', // <title>
  manifestName: `Drobebox | Fashion reInvented`,
  manifestShortName: `Drobebox`, // max 12 characters
  manifestStartUrl: `/`,
  manifestBackgroundColor: '#8bd8ed',
  manifestThemeColor: '#8bd8ed',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/favicon.png',

  // social
  socialLinks: [
    {
      icon: 'icon-social-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/Drobebox/',
    },
    {
      icon: 'icon-social-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/drobebox.official',
    },
  ],
};
